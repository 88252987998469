<template>
  <section class="main">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Total concluídos</p> 
                </div>
                <div v-if="totalChamados > 0">
                  <div class="spinner-grow text-secondary" role="status" v-if="loading">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <h2 class="tertiary" v-if="!loading">{{ totalChamados }}</h2>
                </div>
                <h5 v-else>
                  <h2 class="tertiary" v-if="!loading">0</h2>
                  <small v-if="!loading">Nenhum chamado concluído</small>
                  <div class="spinner-grow" role="status" v-else>
                    <span class="sr-only">Loading...</span>
                  </div>
                </h5>
              </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p class="mutted">Chamados pendentes</p>
              </div>
              <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div></h2>
              <h2 v-else class="tertiary">{{ totalPendentes }}</h2>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Chamados em andamento</p>
              </div>
              <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div></h2>
              <h2 v-else class="tertiary">{{ totalAndamento }}</h2>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Novos chamados</p>
              </div>
              <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div></h2>
              <h2 v-else class="tertiary">{{ totalNew }}</h2>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col pt-5 d-md-flex d-inline text-center justify-content-between">
          <h2>Meus cursos ativos</h2>
          <router-link
            :to="{ name: 'addcurso' }"
            class="btn btn-tertiary mt-3 mt-md-0"
            >+ Publicar novo curso</router-link
          >
        </div> 
      </div>-->
    </div>
    <Dialog header="Cadastro realizado com sucesso!" :visible.sync="EditPassword" :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '80vw' }" :style="{ width: '80vw' }">
          <div class="card">
            <div class="card-header text-center bg-warning">
              <h4>Cadastro realizado com sucesso!</h4>
            </div>
            <div class="card-body text-center">
              <p style=" text-align: justify; ">
                Olá {{ $store.state.user.name }},
                <br>
                Seja bem-vindo à plataforma de atendimento!
                <br>
                <br>
                Para garantir a segurança dos seus dados, foi gerada uma senha automática para a sua conta. Recomendamos que você altere essa senha para uma de sua escolha o quanto antes. Para fazer isso, basta clicar no botão abaixo e seguir as instruções em sua conta.
              </p>
              <router-link role="tab" :to="{name: 'configuracoes'}" class="btn btn-primary btn-lg mb-2 mt-2">Alterar senha</router-link>
              <p style=" text-align: justify; ">
                Lembre-se de escolher uma senha forte e segura que inclua letras maiúsculas e minúsculas, números e caracteres especiais de no mínimo 8 caracteres. Não compartilhe a sua senha com ninguém e atualize-a regularmente.
                <br>
                Se você tiver alguma dúvida ou precisar de ajuda, nossa equipe de suporte está à disposição para ajudá-lo.
              </p>
            </div>
          </div>
      </Dialog>
  </section>
</template>
<script>
import Dialog from "primevue/dialog";
export default {
  name: "HomeAtendente",
  components: {
    Dialog
  },
  data() {
    return {
      loading: true,
      totalChamados:0, 
      totalPendentes:0,
      totalAndamento:0,
      totalNew:0,
      EditPassword:false,
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },

  methods: {
    getRelatorios() {
      this.$http
        .get("/api/atendente/relatorios")
        .then((response) => 
        {
          this.totalChamados  = response.data["totalChamados"];
          this.totalPendentes = response.data["totalPendentes"];
          this.totalAndamento = response.data["totalAndamento"];
          this.totalNew       = response.data["totalNew"];
          
          this.loading = false;
        })
        .catch(() => {});
    }
  },
  mounted() {
    if(this.$store.state.user.text_password != '********'){
      this.EditPassword = true;
    }

    this.getRelatorios();
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}
</style>