import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Dashboard/Home.vue";
import Login from "../views/Login/Login.vue";
import MeuPerfil from "../views/Perfil/MeuPerfil.vue";
import DadosCadastrais from "../views/Perfil/DadosCadastrais.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    meta: {
      login: true, 
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    meta: {
      login: true, 
    },
  },
  {
    path: "#",
    name: "cotacao",
    component: Home,
    meta: {
      login: true, 
    },
  },
  {
    path: "/chamados",
    name: "chamados",
    component: () => 
      import("../views/chamados/Index.vue"),
    meta: {
      login: true,
    },
  },
  
  
  
  
  
  /*######### ROUTER GESTOR #########*/
  {
    path: "/usuarios",
    name: "usuarios",
    component: () =>
      import("../views/usuarios/Index.vue"),
    meta: {
      login: true,
      role: 'gestor' 
    },
  },
  {  
    path: "/plataformas",
    name: "relatorioplataformas",
    component: () =>
      import("../views/WhiteLabel/relatorio.vue"),
    meta: {
      login: true,
      role: 'gestor'
    },
  },
  {
    path: "/admin/chats",
    name: "modelchat",
    component: () =>
      import("../views/WhiteLabel/modelchat.vue"),
    meta: {
      login: true,
      role: 'gestor'
    },
  },
  {
    path: "/plataforma/criar",
    name: "AddPlataforma",
    component: () =>
      import("../views/WhiteLabel/criar.vue"),
    meta: {
      login: true,
      role: 'gestor'
    },
  },
  {
    path: "/administradores",
    name: "cadastroatendente",
    component: () =>
      import("../views/chatbot/cadastro.vue"),
    meta: {
      login: true,
      role: 'gestor'
    },
  },
  {
    path: "/admin/atendentes",
    name: "atendentes",
    component: () =>
      import("../views/WhiteLabel/atendentes/cadastro.vue"),
    meta: {
      login: true,
      role: 'whitelabel' 
    },
  },


  /*######### ROUTER GESTOR #########*/
  /*
  {
    path: "/relatorios",
    name: "relatoriosatendente",
    component: () =>
      import("../views/relatorios/Indexrelatoriosatendente.vue"),
    meta: {
      login: true,
      role: 'gestor'
    },
  },
  */
  {
    path: "/atendente/relatorios",
    name: "atendenterelatorios",
    component: () =>
      import("../views/relatorios/relatoriosatendente.vue"),
    meta: {
      login: true,
      role: 'atendente'
    },
  },

  {
    path: "/admin/relatorios",
    name: "relatorioswhitelabel",
    component: () =>
      import("../views/WhiteLabel/relatorios/Index.vue"),
    meta: {
      login: true,
      role: 'whitelabel'
    },
  }, 

  {
    path: "/chatbot",
    name: "chatbot",
    component: () =>
      import("../views/chatbot/Index.vue"),
    meta: {
      login: true,
      role: 'gestor'
    }
  },
/*
  {
    path: "/cms",
    name: "cms",
    component: () =>
      import("../views/CMS/Index.vue"),
    meta: {
      login: true,
      role: 'gestor'
    }
  },
  */
  {
    path: "/adicionar/licitacao",
    name: "addproducts",
    component: () =>
      import("../views/Produtos/Index.vue"),
    meta: {
      login: true,
      role: 'gestor'
    }
    
  },
  {
    path: "/ajuda",
    name: "ajuda",
    component: () =>
      import("../views/Ajuda/Ajuda.vue"),
    meta: {
      login: true,
    },
  },
  
  {
    path: "/chamado/:protocol",
    name: "chamado",
    component: () =>
      import("../views/WhiteLabel/chamado.vue"),
  },

  {
    path: "/chamado",
    name: "chamadoseach",
    component: () =>
      import("../views/WhiteLabel/chamado.vue"),
  },

  {
    path: "/recuperar/:token",
    name: "recuperar",
    component: () =>
      import(
        "../views/Login/Recuperar.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
  },
  {
    path: "/chat/:token",
    name: "chat",
    component: () =>
    import(
      "../views/Site/Chat.vue"
    ),
    props: true,
  },
/*
  {
    path: "/",
    name: "site",
    component: () =>
    import(
      "../views/Site/Index.vue"
    ),
    props: true,
  },
  */
  /*
  {
    path: "/chat",
    name: "chat",
    component: () =>
    import(
      "../views/Site/Chat.vue"
    ),
    props: true,
  },
  
  {
    path: "/cadastre-se",
    name: "cadastro", 
    component: () =>
    import(
      "../views/Login/Cadastre.vue"
    ),
    props: true,
  },
  */
  {
    path: "/meu-perfil",
    name: "perfil",
    component: MeuPerfil,
    props: true,
    meta: {
      login: true,
    },
    children: [
      {
        path: "",
        name: "meuperfil",
        component: DadosCadastrais,
      },
      {
        path: "configuracoes",
        name: "configuracoes",
        meta: {
          role: 'Aluno',
        },
        component: () =>
          import(
            "../views/Perfil/Configuracoes.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.sessionStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
