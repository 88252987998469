<template>
    <fragment>
        <section class="main">
            <div class="container pb-5">
                <div class="row">
                    <div class="col pt-5 d-md-flex d-inline text-center justify-content-between">
                    </div>
                </div>

            </div>
        </section>
    </fragment>
</template>
    
<script>
import { mapFieldsDadosCadastrais } from "../helpers.js";
import { getCep } from "../services";

import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

import Dialog from "primevue/dialog";
import Rating from "primevue/rating";
import AutoComplete from "primevue/autocomplete";
import { filterByValue } from "../helpers.js";
export default {
    name: "Produtos",
    components: { Rating, AutoComplete, Dialog, cpf, cnpj },
    props: {
        role: String,
    },
    data() {
        return {
            city:'',
            uf:'',
            search:'',

            dataInicial: null,
            dataFinal:  null,
            dataRelatorio:[],
            selectedOption: 'STATUS',
            bins: [],
            pendente: true,
            prosseg: true,
            form: false,
            openwhat: false,
            alterado: false,
            idItemDeCatalogo: 1167,
            dadosCadastrais: {},
            closeModal: true,
            name: !this.$store.state.user.name ? '' : this.$store.state.user.name,
            cpf: this.$store.state.user.cpf,

            organ: !this.$store.state.user.organ ? '' : this.$store.state.user.organ,
            cnpj: !this.$store.state.user.cnpj ? '' : this.$store.state.user.cnpj,
            bairro: !this.$store.state.user.bairro ? '' : this.$store.state.user.bairro,
            telefone: !this.$store.state.user.telefone ? '' : this.$store.state.user.telefone,
            ddd: !this.$store.state.user.ddd ? '' : this.$store.state.user.ddd,
            cidade: !this.$store.state.user.cidade ? '' : this.$store.state.user.cidade,
            uf: !this.$store.state.user.uf ? '' : this.$store.state.user.uf,
            endereco: !this.$store.state.user.endereco ? '' : this.$store.state.user.endereco,
            comp: !this.$store.state.user.comp ? '' : this.$store.state.user.comp,
            numero: !this.$store.state.user.numero ? '' : this.$store.state.user.numero,
            cep: !this.$store.state.user.CEP ? '' : this.$store.state.user.CEP,

            open: false,

            datenew: new Date().getTime(),
            open: false,
            idpedido: '',
            produtos: [],
            slug: '',
            listlicitacoes: [],
            busca: [],
            loading: false,
            ufs: {
                uf: [
                    { "nome": "ESTADO", "sigla": "" },
                    { "nome": "Acre", "sigla": "AC" },
                    { "nome": "Alagoas", "sigla": "AL" },
                    { "nome": "Amapá", "sigla": "AP" },
                    { "nome": "Amazonas", "sigla": "AM" },
                    { "nome": "Bahia", "sigla": "BA" },
                    { "nome": "Ceará", "sigla": "CE" },
                    { "nome": "Distrito Federal", "sigla": "DF" },
                    { "nome": "Espírito Santo", "sigla": "ES" },
                    { "nome": "Goiás", "sigla": "GO" },
                    { "nome": "Maranhão", "sigla": "MA" },
                    { "nome": "Mato Grosso", "sigla": "MT" },
                    { "nome": "Mato Grosso do Sul", "sigla": "MS" },
                    { "nome": "Minas Gerais", "sigla": "MG" },
                    { "nome": "Pará", "sigla": "PA" },
                    { "nome": "Paraíba", "sigla": "PB" },
                    { "nome": "Paraná", "sigla": "PR" },
                    { "nome": "Pernambuco", "sigla": "PE" },
                    { "nome": "Piauí", "sigla": "PI" },
                    { "nome": "Rio de Janeiro", "sigla": "RJ" },
                    { "nome": "Rio Grande do Norte", "sigla": "RN" },
                    { "nome": "Rio Grande do Sul", "sigla": "RS" },
                    { "nome": "Rondônia", "sigla": "RO" },
                    { "nome": "Roraima", "sigla": "RR" },
                    { "nome": "Santa Catarina", "sigla": "SC" },
                    { "nome": "São Paulo", "sigla": "SP" },
                    { "nome": "Sergipe", "sigla": "SE" },
                    { "nome": "Tocantins", "sigla": "TO" }
                ]
            }
        };
    },
    filters: {
        formatDate(value) {
            /*
            var options = { year: "numeric", month: "numeric", day: "numeric" };
            return value.toLocaleString("pt-BR", options);*/
            var date = new Date(value * 1000); // converte para data
            date = date.toLocaleDateString("pt-BR");
            return date;
        },
        currency(valor) {
            var formatter = new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
            return formatter.format(valor);
        },
    },
    computed: {
        ...mapFieldsDadosCadastrais({
            base: "",
            mutation: "UPDATE_DADOS_CADASTRAIS",
            fields: [
                "email",
            ],
        }),
        messageType() {
            return {
                color: this.valido ? 'green' : 'red'
            }
        }
    },
    methods: {
        FiltroTab() {
            if (this.dataInicial == null) {
                this.dataI = new Date('2000/01/01').getTime();
                this.dataInicial = null;
            } else {
                this.dataI = new Date(this.dataInicial).getTime();
                this.dataInicial = null;
            }

            if (this.dataFinal == null) {
                this.dataF = new Date('3000/12/31').getTime();
                this.dataFinal = null;
            } else {
                this.dataF = new Date(this.dataFinal).getTime();
                this.dataFinal = null;
            }
            //this.uf
            //this.city
            this.$http
                .get("/api/licitacao/licitacoes")
                .then((response) => {
                    //this.relatorio = response.data[this.baseRelatori];
                    this.produtos = [];
                    this.produtos = response.data['bids'].filter(result => {
                        
                        var datef = '';
                        datef = new Date(result.date * 1000);
                        var Ddate = datef.getFullYear() + "-" + (datef.getMonth() + 1) + "-" + datef.getDate();
                        if (new Date(Ddate).getTime() > 0) {
                            if(this.city == '' && this.uf == ''){
                                return new Date(Ddate).getTime() >= this.dataI && new Date(Ddate).getTime() <= this.dataF && result.goal.toLowerCase().match(this.search.toLowerCase());
                            }else if(this.city != '' && this.uf == ''){
                                return new Date(Ddate).getTime() >= this.dataI && new Date(Ddate).getTime() <= this.dataF && result.organ.cidade.toLowerCase().includes(this.city.toLowerCase()) && result.goal.toLowerCase().match(this.search.toLowerCase());
                            }else if(this.city == '' && this.uf != ''){
                                return new Date(Ddate).getTime() >= this.dataI && new Date(Ddate).getTime() <= this.dataF && result.organ.uf == this.uf && result.goal.toLowerCase().match(this.search.toLowerCase());
                            }else if(this.city != '' && this.uf != ''){
                                return new Date(Ddate).getTime() >= this.dataI && new Date(Ddate).getTime() <= this.dataF && result.organ.uf == this.uf && result.organ.cidade.toLowerCase().includes(this.city.toLowerCase()) && result.goal.toLowerCase().match(this.search.toLowerCase());
                            }
                        }
                        

                    })

                })
                .catch(() => { });

        },
        onChange() {
            if(this.selectedOption == 'ATIVO'){
                this.$http
                .get("/api/licitacao/licitacoes")
                .then((response) => {
                    //this.bins = response.data;
                    //this.produtos = this.bins['bids'];
                    this.produtos = [];
                    this.produtos = response.data['bids'].filter(result => {
                        if(result.status){
                            return result;
                        }
                    });
                })
                .catch(() => { });
            }else if(this.selectedOption == 'EXPIRADO'){
                this.$http
                .get("/api/licitacao/licitacoes")
                .then((response) => {
                    //this.bins = response.data;
                    //this.produtos = this.bins['bids'];
                    this.produtos = [];
                    this.produtos = response.data['bids'].filter(result => {
                        if(this.datenew > (result.date * 1000))
                        return result;
                    });
                })
                .catch(() => { });
            }
            this.load = false;
        },

        irProduto(event) {
            this.$router.push({
                name: "produto",
                params: { slug: event.value.slug },
            });
        },
        RequestOpen(key) {
            this.idpedido = key;
            if (this.$store.state.user.cnpj == undefined || this.$store.state.user.cnpj == null || this.$store.state.user.cnpj == "") {
                this.openwhat = true;
            } else {
                this.open = true;
            }

        },
        buscarProdutos(event) {
            this.$http
                //.get(`/api/collections/get/categorias/`)
                .get(`/api/licitacao/licitacoes`)
                .then((response) => {
                    this.listlicitacoes = response.data.filter(item => item.title.toLowerCase().match(event.query.toLowerCase()));
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        getProdutos() {
            this.$http
                .get("/api/licitacao/licitacoes")
                .then((response) => {
                    this.bins = response.data;
                    this.produtos = this.bins['bids'];
                })
                .catch(() => { });
        },
        Requests() {
            this.loading = true;

            let formData = new FormData();

            formData.append('data', this.idpedido);
            this.$http.
                post('/api/pedido/criar', formData)
                .then(() => {
                    this.getProdutos();
                    this.loading = false;
                    this.open = false;
                    this.$toast.open({
                        message: "Pedido de Licitação solicitado com sucesso!",
                        type: "success",
                        duration: 3600,
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast.open({
                        message: error.response.data.error,
                        type: "error",
                    });
                });
        },
        Mybins() {
            this.produtos = this.bins['mybids'];
        },
        Allbins() {
            this.produtos = this.bins['bids'];
        },

        preencherCep() {
            if (this.cep.length > 8) {
                getCep(this.cep.replace(/-/, "")).then((response) => {
                    if (response.data.cep != undefined) {
                        this.endereco = response.data.logradouro;
                        this.bairro = response.data.bairro;
                        this.cidade = response.data.localidade;
                        this.uf = response.data.uf;
                    } else {
                        this.$toast.open({
                            message: "CEP não localizado.",
                            type: "error",
                            duration: 3600,
                        });
                    }
                });
            }
        },
        ValidDocument(document) {
            return cnpj.isValid(document);
        },
        salvarDadosCadastrais() {
            if (this.ValidDocument(this.cnpj) == false) {
                this.pendente = false;
                this.$toast.open({
                    message: "CNPJ Inválido.",
                    type: "error",
                });
                this.loading = false;
            } else {
                this.pendente = true;
                this.loading = true;
                var userData = {
                    _id: this.$store.state.user._id,
                    name: this.name,
                    cpf: this.$store.state.user.cpf,
                    organ: this.organ,
                    cnpj: this.cnpj,
                    bairro: this.bairro,
                    telefone: this.telefone,
                    ddd: this.ddd,
                    cidade: this.cidade,
                    uf: this.uf,
                    endereco: this.endereco,
                    comp: this.comp,
                    numero: this.numero,
                    CEP: this.cep,
                };
                this.$http
                    .post(`/api/user/cadastro`, { user: userData })
                    .then(() => {
                        //ATUALIZAR DADOS NO LOCALSTORAGE

                        this.$http
                            .get("/api/user/me")
                            .then(response => {
                                this.$store.commit("UPDATE_USER", response.data);

                                this.loading = false;
                                this.$toast.open({
                                    message: "Dados atualizados com sucesso !",
                                    type: "success",
                                });

                                setTimeout(() => {
                                    this.openwhat = false;
                                    this.RequestOpen(this.idpedido);
                                }, 1000);
                            });
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$toast.open({
                            message: error.message,
                            type: "error",
                        });
                    });
            }
        },
        setAvatar() {
            var avatar = this.$refs.avatar.files[0];
            var formData = new FormData();
            formData.append("avatar", avatar);

            this.$http
                .post("/api/user/update", {
                    avatar: formData,
                })
                .then((response) => {
                    this.$toast.open({
                        message: "Avatar alterado com sucesso !",
                        type: "success",
                    });
                })
                .then(() => {
                    this.getUserData();
                })
                .catch((error) => {
                    this.$toast.open({
                        message: error.message,
                        type: "error",
                    });
                });
        },
    },
    mounted() {
        this.getProdutos();
        this.uf = '';
    },
};

</script>
<style lang="scss" scoped>
@media screen and (max-width: 575px) {
.card-title{
    text-align: left;
    margin-bottom: 0px !important;
}
h2{
    font-size: 1.5rem !important;
}
}
.status-left {
    left: 0;
    border-bottom-right-radius: 15px;
    width: fit-content;
    padding: 0.4rem;
    color: white;
    position: absolute;
}

.on {
    background: var(--primary) !important;
}

.off {
    background: var(--danger) !important;
}

.expired {
    background: var(--warning) !important;
    display: inline;
}

.inf {
    background: var(--medium-tint) !important;
}

.op-5 {
    opacity: 0.5;
}

.inline-block {
    display: inline-block;
}

.form_padrao {
    .form-group.flutuante {
        position: relative;

    }

    .fit-content {
        max-width: fit-content;
        padding: 0.6rem 1rem !important;
    }
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background: var(--primary) !important;
    opacity: 0.9;
    color: white;
}

.text-just {
    text-align: justify;
}

span.p-autocomplete.p-component.p-autocomplete-dd {
    width: 100%;
}

button.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    height: auto;
}

input,
select {
    border: 1px solid var(--primary) !important;
}

@media screen and (max-width: 900px) {
    .category-list {
        width: 100vw;
        overflow-y: hidden;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.form_padrao {
    .form-group.flutuante {
        position: relative;

    }

    .fit-content {
        max-width: fit-content;
        padding: 0.6rem 1rem !important;
    }
}

.category-list {
    .badge {
        min-width: 100px;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        background: var(--white);
        color: var(--dark-2);
        cursor: pointer;

        &:hover {
            background: var(--tertiary);
            color: var(--white);
        }
    }

    .badge.active {
        background: var(--tertiary);
        color: var(--white);
    }
}

.img-fluid {
    max-height: 15rem;
    height: auto;
    width: fit-content;
}

.product {
    min-height: 10rem;
}

.status {
    background: var(--primary);
    border-bottom-left-radius: 15px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.4rem;
    color: white;
    position: absolute;
    right: 0;
}

.status-in {
    background: var(--danger);
    border-bottom-left-radius: 15px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.4rem;
    color: white;
    position: absolute;
    right: 0;
}

.location {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    line-height: 0;
    margin-left: -5px;
}

.br-10 {
    border-radius: 10px;
}
</style>