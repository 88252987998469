<template>
  <div id="login">
    <transition mode="in-out">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 p-3 d-flex justify-content-center align-content-center"
          > 
            <img
              src="@/assets/logo_white.png"
              class="img-fluid"
              width="200"
              alt="logo"
            />
          </div>
        </div>

        <div class="row" v-if="asLogin" key="login">
          <div class="col form_padrao shadow-sm">
            <h5 class="pb-4 text-uppercase text-center">Acesse sua conta</h5>
            <form action @submit.prevent="login">
              <div class="form-group flutuante">
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  v-model="userData.user"
                />
                <label for="email">Seu E-mail</label>
              </div>
              <div class="form-group flutuante">
                <input
                  type="password"
                  name="senha"
                  id="senha"
                  required
                  v-model="userData.password"
                />
                <label for="senha">Senha</label>
              </div>
              <div class="form-group">
                <div
                  class="alert alert-danger border-0 text-center"
                  v-if="error"
                  role="alert"
                >
                  {{
                    errorMsg == 401
                      ? "Usuário ou senha inválidos"
                      : "falha ao autenticar"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-7 col-6 align-self-center font-13">
                  <a
                    href="javascript:void(0)"
                    @click="asLogin = false"
                    class="esqueci"
                    >Esqueci minha senha</a
                  >
                </div>
                <div class="col-md-5 col-6">
                  <button
                    type="submit"
                    class="btn btn-verde p-0"
                    :disabled="loading"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm pr-2"
                      role="status"
                      aria-hidden="true"
                      v-show="loading"
                    ></span>
                    <span v-if="loading">Aguarde</span>
                    <span v-else>Entrar</span>
                  </button>
                </div>
              </div>
            </form>
            <hr class="text-muted" />
			<!--
            <p class="text-center small">
              Ainda não é cadastrado ?
              <router-link :to="{ path: 'cadastre-se' }">Crie sua conta.</router-link>
            </p>
            -->
          </div>
        </div>
        <div class="row" v-else key="esqueci">
          <div class="col form_padrao shadow-sm">
            <h5 class="pb-4 text-uppercase text-center">Esqueci minha senha</h5>
            <form action @submit.prevent="recuperarSenha">
              <div class="form-group flutuante">
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  v-model="userData.userName"
                />
                <label for="email">Informe seu e-mail</label>
              </div>
              <div class="row">
                <div class="col-md-7 col-6 align-self-center font-13">
                  <a
                    href="javascript:void(0)"
                    @click="asLogin = true"
                    class="esqueci d-flex align-items-center"
                  >
                    <i class="material-icons pr-1">arrow_left</i> Voltar para
                    login
                  </a>
                </div>
                <div class="col-md-5 col-6">
                  <button
                    type="submit"
                    class="btn btn-verde p-0"
                    :disabled="loading"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm pr-2"
                      role="status"
                      aria-hidden="true"
                      v-show="loading"
                    ></span>
                    <span v-if="loading">Aguarde</span>
                    <span v-else>Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      asLogin: true,
      userData: {
        user: "",
        password: "",
      },
      loading: false,
      error: false,
      errorMsg: "",
    };
  },

  methods: {
    recuperarSenha() {
      this.loading = true;
      this.error = false;
      this.$http
        .get(`api/user/recSenha?type=aluno&codigo=${this.userData.userName}`)
        .then(() => {
          this.$toast.open({
            message: "Solicitação enviada ! Verifique sua caixa de e-mail.",
            type: "success",
            duration: 4000,
          });
          this.loading = false;
          this.asLogin = true;
          this.userData = {};
        })
        .catch(() => {
          this.$toast.open({
            message: "E-mail não cadastrado!",
            type: "error",
          });
          this.loading = false;
          this.error = true;
        });
    },
    login() {
      this.loading = true;
      this.error = false;
      this.$http
        .login(this.userData)
        .then((response) => {
          this.$store.state.user = [];
          try {
            //Set Token
            window.sessionStorage.token = `Bearer ${response.data.api_key}`;
            //Set user data
            window.sessionStorage.userData = JSON.stringify(response.data);
            this.$store.commit("UPDATE_USER", response.data);

            this.$router.push({ name: "Dashboard" });
          } catch (e) {
            // tratamento do erro
            console.error('Ocorreu um erro ao acessar o sessionStorage: ', e.message);
          }
        })
        .catch((error) => {
          if(error.response.data.error == false){
            this.$toast.open({
              message: 'Usuário bloqueado!',
              type: "error",
            });
          }else{
            this.$toast.open({
              message: "Login ou senha inválidos, tente novamente",
              type: "error",
            });
          }
          this.loading = false;
          this.error = true;
          this.errorMsg = error.response.status;
        });
    },
  },
  created() {
    if (this.$route.query.id) {
      window.sessionStorage.cursoId = this.$route.query.id;
    }
    else{
       sessionStorage.cursoId = ""
    }

    document.title = this.$titleSite;
    window.addEventListener("offline", () => {
      this.$toast.open({
        message: "Sem conexão com a internet.",
        type: "error",
        duration: 3000,
      });
    });
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  #login .form_padrao{
    margin-top: 5vh;
  }
</style>
