<template>
  <section class="main">
    <form class="form_padrao" action v-on:submit.prevent="salvarDadosCadastrais">
      <div class="form-row mb-4 d-flex d-md-inline-flex justify-content-center">
        <div class="position-relative avatar d-none">
          <img :src="'https://ui-avatars.com/api/?size=128&background=0D8ABC&color=fff&name=' + $store.state.user.name"
            class="float-left" :alt="$store.state.user.name" />
          <i class="material-icons small text-muted position-absolute camera">camera_alt</i>
          <input type="file" @change="setAvatar()" ref="avatar" name="avatar" title="Alterar Foto" id="avatar"
            class="position-absolute" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group flutuante col-md-12">
          <input type="text" name="name" id="name" required v-model="name" />
          <label for="name">Nome Completo</label>
        </div>
        <div class="form-group flutuante col-md-12">
          <input type="text" name="doc" id="cpf" class="op-5" v-model="cpf" required v-mask="['###.###.###-##', '##.###.###/####-##']"
            disabled />
          <!--<label for="cpf">Documento</label>-->
        </div>


        <div class="form-group flutuante col-md-12" v-if="$store.state.user.group != 'atendente'">
          <input type="text" name="organ" id="organ" required v-model="organ" />
          <label for="organ">Orgão - Razão Social</label>
        </div>
        <div class="form-group flutuante col-md-12" v-if="$store.state.user.group != 'atendente'">

          <input type="text" name="cnpj" id="cnpj" v-model="cnpj" required v-mask="['##.###.###/####-##']"/>

          <label for="cnpj">CNPJ</label>
        </div>


        <div class="form-group flutuante col-md-6">
          <input type="text" name="email" id="email" class="op-5" v-model="email" disabled required />
          <label for="email"></label>
        </div>

        <div class="form-group flutuante col-md-6">
          <input type="text" name="telefone" id="telefone" v-model="telefone"
            v-mask="['(##) ####-####', '(##) #####-####']" />
          <label for="telefone">Telefone</label>
        </div>
        <div class="form-group flutuante col-md-3">
          <input type="text" name="cep" id="cep" ref="cep" v-model="cep" @keyup="preencherCep" v-mask="['#####-###']" />
          <label for="lograd">CEP</label>
        </div>
        <div class="form-group flutuante col-md-6">
          <input type="text" name="endereco" id="endereco" ref="endereco" v-model="endereco" />
          <label for="lograd">Endereço</label>
        </div>
        <div class="form-group flutuante col-md-3">
          <input type="text" name="bairro" id="bairro" ref="bairro" v-model="bairro" />
          <label for="bairro">Bairro</label>
        </div>
        <div class="form-group flutuante col-md-2">
          <input type="text" name="numero" id="numero" v-model="numero" />
          <label for="numero">Número</label>
        </div>
        <div class="form-group flutuante col-md-4">
          <input type="text" name="cidade" id="cidade" ref="cidade" v-model="cidade" />
          <label for="cidade">Cidade</label>
        </div>
        <div class="form-group flutuante col-md-2">
          <input type="text" name="uf" id="uf" ref="uf" v-model="uf" />
          <label for="uf">Estado</label>
        </div>
        <div class="form-group flutuante col-md-4">
          <input type="text" name="comp" id="comp" v-model="comp" />
          <label for="comp">Complemento</label>
        </div>
        <div class="form-group col-12 d-flex flex-row justify-content-end align-items-end">
          <button type="submit" class="btn btn-verde p-0 fit-content" :disabled="loading">
            <span class="spinner-grow spinner-grow-sm pr-2" role="status" aria-hidden="true" v-show="loading"></span>
            <span v-if="loading">Salvando...</span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
 
<script>
import { mapFieldsDadosCadastrais } from "../../helpers.js";
import { getCep } from "../../services";

import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

export default {
  name: "DadosCadastrais",
  components: { cpf, cnpj },
  data() {
    return {
      pendente: true,
      loading: false,
      alterado: false,
      idItemDeCatalogo: 1167,
      dadosCadastrais: {},
      closeModal: true,
      name: !this.$store.state.user.name ? '' : this.$store.state.user.name,
      cpf: this.$store.state.user.cpf,

      organ: !this.$store.state.user.organ ? '' : this.$store.state.user.organ,
      cnpj: !this.$store.state.user.cnpj ? '' : this.$store.state.user.cnpj,
      
      bairro: !this.$store.state.user.bairro ? '' : this.$store.state.user.bairro,
      telefone: !this.$store.state.user.telefone ? '' : this.$store.state.user.telefone,
      ddd: !this.$store.state.user.ddd ? '' : this.$store.state.user.ddd,
      cidade: !this.$store.state.user.cidade ? '' : this.$store.state.user.cidade,
      uf: !this.$store.state.user.uf ? '' : this.$store.state.user.uf,
      endereco: !this.$store.state.user.endereco ? '' : this.$store.state.user.endereco,
      comp: !this.$store.state.user.comp ? '' : this.$store.state.user.comp,
      numero: !this.$store.state.user.numero ? '' : this.$store.state.user.numero,
      cep: !this.$store.state.user.CEP ? '' : this.$store.state.user.CEP,
    };
  },
  computed: {
    ...mapFieldsDadosCadastrais({
      base: "",
      mutation: "UPDATE_DADOS_CADASTRAIS",
      fields: [
        "email",
      ],
    }),
  },
  methods: {
    ValidDocument(document){
      return cnpj.isValid(document);
    },
    preencherCep() {
      if (this.cep.length > 8) {
        getCep(this.cep.replace(/-/, "")).then((response) => {
          if (response.data.cep != undefined) {
            this.endereco = response.data.logradouro;
            this.bairro = response.data.bairro;
            this.cidade = response.data.localidade;
            this.uf = response.data.uf;
          } else {
            this.$toast.open({
              message: "CEP não localizado.",
              type: "error",
              duration: 3600,
            });
          }
        });
      }
    },
    salvarDadosCadastrais() {

    if (this.ValidDocument(this.cnpj) == false && this.$store.state.user.group != 'atendente') {
          this.pendente = false;
          this.$toast.open({
            message: "CNPJ Inválido.",
            type: "error",
          });
          this.loading = false;
    } else {
          this.loading = true;
          var userData = {
            _id: this.$store.state.user._id,
            name: this.name,
            cpf: this.$store.state.user.cpf,
            

            organ: this.organ,
            cnpj: this.cnpj,

            bairro: this.bairro,
            telefone: this.telefone,
            ddd: this.ddd,
            cidade: this.cidade,
            uf: this.uf,
            endereco: this.endereco,
            comp: this.comp,
            numero: this.numero,
            CEP: this.cep,
          };
          this.$http
            .post(`/api/user/cadastro`, { user: userData })
            .then(() => {
              //ATUALIZAR DADOS NO LOCALSTORAGE

              this.$http
                .get("/api/user/me")
                .then(response => {
                  this.$store.commit("UPDATE_USER", response.data);
                });

              this.loading = false;
              this.$toast.open({
                message: "Dados atualizados com sucesso !",
                type: "success",
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast.open({
                message: error.message,
                type: "error",
              });
            });
        }
    },
    setAvatar() {
      var avatar = this.$refs.avatar.files[0];
      var formData = new FormData();
      formData.append("avatar", avatar);

      this.$http
        .post("/api/user/update", {
          avatar: formData,
        })
        .then((response) => {
          console.log(response);
          this.$toast.open({
            message: "Avatar alterado com sucesso !",
            type: "success",
          });
        })
        .then(() => {
          this.getUserData();
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.op-5{
    opacity: 0.5;
}
.form_padrao {
  .form-group.flutuante {
    position: relative;

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    textarea {
      background: white;
    }
  }

  .fit-content {
    max-width: fit-content;
    padding: 0.6rem 1rem !important;
  }
}

@media screen and (max-width: 900px) {
  .fit-content {
    max-width: 100% !important;
  }

  .alert-warning {
    font-size: 0.7rem;
  }

  .close {
    display: none;
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 5px;
}

.message {
  max-width: 700px;
  display: flex;
  margin: 0 auto;
  padding: 0.6rem 0;
}

.sepia {
  color: white !important;
  background: #856404 !important;
  border: 1px solid #856404 !important;
}

@media screen and (max-width: 900px) {
  .close {
    display: block;
  }
}

.avatar {
  img {
    border-radius: 50%;
  }

  input {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .camera {
    left: 40%;
    bottom: 5%;
    color: var(--white) !important;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }
}
</style>