<template>
    <fragment v-if="OpenChatIcon">
        <input type="checkbox" id="check">
        <label class="chat-btn btn btn-white btn-animate label" for="check">
            <img v-bind:src="$apiURL + 'images/logo.png'" class="img-fluid comment" width="60" alt="logo" @click="OpenChat($event)" >
            <i class="material-icons close" @click="CloseChat($event)" > close </i>
        </label>
        <div class="wrapper">
            <div class="header" >
                <div class="row">
                    <div class="col-md-3 col-3">
                        <img v-bind:src="$apiURL + 'images/logo.png'" class="img-fluid logo-avatar" width="60" alt="logo">
                    </div>
                    <div class="col-md-7  col-7 m-auto" style="margin-left: 0px !important;">
                        <h3>Eugênio</h3>
                    </div>    
                </div>
            </div>
            <div class="text-center p-2">
                <ul class="messages" ref="messages" id="messages">
                    <!--<div v-for="message in listmessages" :key="message.id">-->
                    <div v-for="(message, index) in listmessages" :key="`msg-${index}`">
                        <li class="message text-right ml-5 mr-4 mt-1" v-if="message.model == 'questionario'">
                            <p class="msg tex link" v-html="message.text" v-on:click="OpcaoChat(index)"></p>
                        </li>
                        <li class="message text-left ml-4 mr-5 mt-1" v-else-if="message.model == 'resposta'">
                            <p class="msg tex resposta" v-html="message.text"></p>
                        </li>
                        <li class="message text-right ml-5 mr-4 mt-1" v-else>
                            <p class="msg tex" v-html="message.text"></p>
                        </li>
                    </div>
                    <li class="message mt-1 float-right lod">
                        <loading color="var(--primary)" v-if="load == true"/>
                    </li>
                    <!-- #ICONE DIGITANDO
                    <li class="message text-right mr-4 mt-1" style=" float: right;">
                        <loading color="var(--chat)"/>
                    </li>
                    -->
                </ul>
            </div>
            <div class="chat-form">
                <div v-show="form == true">
                    <form action v-on:submit.prevent="PostMgs">
                        <textarea class="form-control" placeholder="Sua Mensagem de Texto" required v-model="textarea"></textarea>
                        <input type="file" name="file" id="file" ref="file" multiple @change="onChangeFileUpload()" v-show="formInput"/>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true">Enviar</button>
                    </form>
                </div>
                <div v-if="name == true">
                    <form action v-on:submit.prevent="Check('name', false)">
                        <textarea class="form-control" placeholder="Digite seu nome completo" required v-model="textarea"></textarea>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true">Enviar</button>
                    </form>
                </div>
                <div v-if="cpf == true">
                    <form action v-on:submit.prevent="Check('cpf', false)">
                        <input type="text" class="form-control" placeholder="Digite seu CPF" required autocomplete="off" v-mask="['###.###.###-##']" v-model="textarea" style=" height: 62px; ">
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true">Enviar</button>
                    </form>
                </div>
                <div v-if="telefone == true">
                    <form action v-on:submit.prevent="Check('telefone', false)">
                        <input type="text" class="form-control" placeholder="Digite seu telefone" autocomplete="off" v-mask="['(##)#####-####']" required v-model="textarea" style=" height: 62px; ">
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true">Enviar</button>
                    </form>
                </div>
                <div v-if="checkDate == true"> 
                    <form action v-on:submit.prevent="PostDate" class="input-group">
                        <!--
                        <input type="date" class="form-control bg-primary mr-1" required v-model="formData.timestamp.day" ref="day" style=" height: 62px; ">
                        <input type="time" class="form-control bg-primary ml-1" required v-model="formData.timestamp.hour" style=" height: 62px; ">
                        -->
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true">Confirma</button>
                </form>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
export default {
  name: "Chat", 
    props: {
  },
data() {
    return {
        group:'usuario',
        color:'--var(primary)',
        OpenChatIcon:false,
        pendente: true,
        valido: false,
        load:'',
        listmessages: [],
        checkout:false,
        messagefim:false,
        close:false,
        dateMin:"",
        Template:[],
        scrollTop:0,
        i:0,
        e:1,
        time:1,
        textarea:'',
        file:[],
        formData: {
                user_id:"",
                name:"",
                cpf:"",
                telefone:"",

                group:"",
                platform: this.$titleSite,
                option:"",
                text:"",
                timestamp:{
                            day:"",
                            hour:""
                        },
                files:[],
                },
        conclusao:false,
        form:true,
        formInput:true,
        name:false,
        cpf:false,
        telefone:false,
        checkDate:false,
        inicio:0,
    };
  },
  methods: {
    ValidDocument(document){
      if(document.replace(/[^0-9]/g,'').length == 11){
        return cpf.isValid(document);
      }else{
        return cnpj.isValid(document);
      }
    },
    scrollTo(value){
        setTimeout(() => {
            this.$refs.messages.scrollTop = value;
        }, 100);
    },
    msgload(boolean){
        this.load = boolean;
    },
    OpenChat() {
        this.messagefim = false;
        if(this.close == true){
            this.listmessages = [];
            this.close = false;
        }
        setTimeout(() => {
            this.msgload(true);
        }, 1000);

        setTimeout(() => {
            //IMPRIMIR TODAS AS MENSAGENS => abordagem + questionario
            if (this.i < this.inicio) {
                this.msgload(false);
                this.listmessages.push(this.Template[this.i]);
                this.scrollTop += 100;
                this.time = 2;
                this.scrollTo(this.scrollTop);
                this.i++;
                this.msgload(true);
                this.OpenChat();
            }else{
                this.msgload(false);
                this.checkout = true;
                this.i = 0;
            }
        }, (this.time * 1000));
    },
    Opcoes() {
        this.msgload(true);
        setTimeout(() => {
            if (this.e < this.inicio) {
                this.msgload(false);
                this.listmessages.push(this.Template[this.e]);
                this.scrollTop += 100;
                //this.time = this.Template[this.e]["messages"]["time"];
                this.scrollTo(this.scrollTop);
                this.e++;
                this.Opcoes();
            }else{
                this.msgload(false);
                this.e = 1;
            }
        }, (this.time * 1000));
    },
    OpcaoChat(opc) {
        this.messagefim = false;
        this.msgload(true);
        if(this.checkout == false){
            this.msgload(false);
            /*
            if(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != ''){
                this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                this.scrollTop += 100;
                this.scrollTo(this.scrollTop);
            }*/
        }else{
            /*
            this.msgload(false);
            if(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != ''){
                this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                this.scrollTop += 100;
                this.scrollTo(this.scrollTop);
            }*/
            this.msgload(true);
            setTimeout(() => {
                /*
                for(var m = 0; m < this.Template.length; m++){
                    if(this.Template[m]["messages"]["id"] == opc){
                        this.msgload(false);
                        this.listmessages.push(this.Template[m]["messages"]["opcao"]);
                        this.formData.option = this.Template[m]["messages"]["text"];
                        this.messagefim = true;
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);
                    }
                }
                */
               this.msgload(false);
                this.listmessages.push(this.Template[opc]["opcao"]);
                this.formData.option = this.Template[opc]["text"];
                this.messagefim = true;
                this.scrollTop += 100;
                this.scrollTo(this.scrollTop);
            }, 3000);
        }
    },
    Erro(){
        for(var t=0; t < this.Template.length; t++){
            if(this.Template[t]["opc"] == "erro"){
                return this.Template[t];
            }
        }
    },
    opc(opc){
        var idopc =[];
        for(var t=0; t < this.Template.length; t++){
            if(this.Template[t]["model"] == "questionario"){
                idopc.push(
                    {
                    'array' : t,
                    'item' : this.Template[t]["cont"],
                    }
                    );
            }
        }
        var retu = {
                    'status' : false
                };
        for(var op = 0; op < idopc.length; op++){
            if(opc == idopc[op]['item']){

                retu = {
                    'array'  : idopc[op]['array'],
                    'status' : true
                };
                return retu;
            }else{
                retu = {
                    'status' : false
                };
            }
        }
        return retu;
    },
    onChangeFileUpload(){
        this.file = this.$refs.file.files;
    },
    PostMgs(){
        if(this.messagefim == true){
            //console.log(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>"));
            //MENSAGEM FINAL PARA PROCESSAMENTO
            this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
            this.scrollTop += 100;
            this.scrollTo(this.scrollTop);
            this.formData.text = this.textarea;
            if(this.file.length > 0){
                for (let file of this.file) {
                    this.listmessages.push(JSON.parse(`{"id": `+this.scrollTop+`, "text":"<i class='material-icons' style='font-size: 30pt;'> upload_file </i>`+file['name'].replace(/(\r\n|\n|\r)/gm, "<br>")+`","model":"resposta"}`));
                }
            }
            this.formInput = false;
            this.PosformChat();
        }else{
            if(this.checkout == false){
                if(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != ''){
                    this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    this.textarea = '';
                }
            }else{
                if(this.textarea != ''){
                    this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                    this.textarea = this.textarea.replace(/([^\d])+/gim, '');
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    var opc = this.textarea;
                    this.textarea = '';
                    
                    var valid = this.opc(opc);
                    if(valid['status'] == true){
                        this.OpcaoChat(valid['array']);
                    }else{
                    setTimeout(() => {
                        this.listmessages = [];
                        this.listmessages.push(this.Erro());
                        this.msgload(true);
                        this.scrollTop = 0;
                        this.scrollTo(this.scrollTop);
                        this.Opcoes();
                        }, 2000);
                    }
                }
            }
        }
    },
    final(tipo, final){
        for(var t=0; t < this.Template.length; t++){
            if(this.Template[t]["model"] == tipo && this.Template[t]["cont"] == final){
              return this.Template[t];
            }
        }
    },
    PosformChat(){
        //this.formData.user = this.userData.email;
        this.textarea = '';
        this.msgload(true);
        setTimeout(() => {
            this.msgload(false);
                /*
                if(this.conclusao == false){
                    this.listmessages.push(this.final('informacao',1));
                    this.scrollTop += 100;
                    this.conclusao = true;
                }
                */
                if(typeof this.formData.name == "undefined" || this.formData.name == ""){
                    this.listmessages.push(this.final('informacao',2));
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    this.Check('name', true);
                }else if(typeof this.formData.cpf == "undefined" || this.formData.cpf == ""){
                    this.listmessages.push(this.final('informacao',3));
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    this.Check('cpf', true);
                }else if(typeof this.formData.telefone == "undefined" || this.formData.telefone == ""){
                    this.listmessages.push(this.final('informacao',4));
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    this.Check('telefone', true);
                }/*else if(typeof this.formData.timestamp.day == "undefined" || this.formData.timestamp.day == ""){
                    this.listmessages.push(this.final('informacao',5));
                    this.scrollTop += 100;
                    this.CheckDate(true);
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                }*/else{
                    /*&
                    if(typeof this.formData.group == "undefined" || this.formData.group == "undefined" || this.formData.group == ""){
                        this.formData.group = "visitante";
                    }*/
                    if(typeof this.$store.state.user.name != "undefined" || this.$store.state.user.user_id != ""){
                        this.formData.user_id = this.$store.state.user._id;
                    }
                    setTimeout(() => {
                        for(var t=0; t < this.Template.length; t++){
                            if(this.Template[t]["model"] == 'finalizar' && this.Template[t]["cont"] == 1){
                            this.listmessages.push(this.Template[t]);
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            }
                        }
                    }, 1000);

                    this.CloseChat();
                    let formData = new FormData();
                    if(this.file.length > 0){
                        for( var i = 0; i < this.file.length; i++ ){
                            let file = this.file[i];
                            formData.append('file[' + i + ']', file);
                        }
                    }
                    this.textarea = '';
                    formData.append('data', JSON.stringify(this.formData));
                    
                    this.$http.
                    post('/api/usuario/createChat', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+('Seu número de protocolo e: <b>'+response.data.protocol).replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"questionario"}'));

                        this.loading = false;
                        this.$toast.open({
                        message:
                            "Mensagem foi enviada!",
                        type: "success",
                        duration: 3600,
                        });
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.loading = false;
                        this.$toast.open({
                            message: error.response.data.error,
                            type: "error",
                        });
                    });
        
                }
        }, 2000);
  },
  PostDate(){
      //this.CheckDate(false);
      this.PosformChat();
  },
  /*
  CheckDate(value){
      this.checkDate = value;
    if(value == true){
        this.form = false;
        setTimeout(() => {
            var date = new Date();
            this.dateMin = date.getFullYear()+'-'+("00" + (date.getMonth()+1)).slice(-2)+'-'+("00" + date.getDate()).slice(-2);
            this.$refs.day.setAttribute('min',this.dateMin);
                //this.$refs.messages.scrollTop = value;
        }, 100);
    }else{
        this.form = true;
    }
  },*/
  Check(value, check){
      switch (value) {
        case 'name':
            this.name = check;
            if(check == true){
                this.form = false;
            }else{
                this.form = true;
                this.formData.name = this.textarea;
                this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                this.textarea = '';
                this.PosformChat();
            }
        break;
        case 'cpf':
            if(check == false){
                this.valido = this.ValidDocument(this.textarea);
                this.pendente = false;
                if(this.valido == false){
                this.$toast.open({
                    message: "CPF Inválido.",
                    type: "error",
                    });
                this.pendente = false;
                }
                if(this.valido == true){
                    this.cpf = check;
                    if(check == true){
                        this.form = false;
                    }else{
                        this.form = true;
                        this.formData.cpf = this.textarea;
                        this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                        this.textarea = '';
                        this.PosformChat();
                    }
                }
            }else{
                this.cpf = check;
                this.form = false;
            }
        break;
        case 'telefone':
            this.telefone = check;
            if(check == true){
                this.form = false;
            }else{
                this.form = true;
                this.formData.telefone = this.textarea;
                this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                this.textarea = '';
                this.PosformChat();
            }
        break;
      }

    this.scrollTop += 100;
    this.scrollTo(this.scrollTop);
  },
    CloseChat() {
        this.close = true;
    },
    MensageBot(){
        this.Template = [];
        this.$http
        .get("api/user/indexDialogo?group=" + this.formData.group)
        .then((response) => {
            this.Template = response.data;
            var td = 0;
            for(var t=0; t < this.Template.length; t++){
                if(this.Template[t]["model"] == "abordagem" || this.Template[t]["model"] == "questionario"){
                    td += 1;
                }
            }
            this.inicio = td;
            this.OpenChatIcon = true;
        })
        .catch(() => {});
    },
  },
  mounted() {
    /*
      if(typeof this.$store.state.user.name != "undefined" || this.$store.state.user.name != ""){
        this.formData.name = this.$store.state.user.name;
      }
      if(typeof this.$store.state.user.group != "undefined" || this.$store.state.user.group != ""){
          this.formData.group = this.$store.state.user.group; 
      }*/
      /*else{
          this.formData.group = "usuario";
      }*/
      if(typeof this.$store.state.user.cpf != "undefined" || this.$store.state.user.cpf != ""){
        this.formData.cpf = this.$store.state.user.cpf;
      }
      if(typeof this.$store.state.user.telefone != "undefined" || this.$store.state.user.telefone != ""){
        this.formData.telefone = this.$store.state.user.telefone;
      }
    this.formData.group = "visitante";
    this.MensageBot();
  },
};

/*
const validar = cpf => checkAll(prepare(cpf))

const notDig = i => !['.', '-', ' '].includes(i)
const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
const is11Len = cpf => cpf.length === 11
const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
const onlyNum = cpf => cpf.every(i => !isNaN(i))

const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
const resto11 = somaDig => 11 - (somaDig % 11)
const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)
*/
</script>

<style scoped>
i>.material-icons{
    font-size: 30pt !important;
}
.loading{
    padding-bottom: 15px;
}
.lod{
    right: 30px;
    bottom: 170px;
    position: absolute;
}
.label {
  background: #4FD1C5;
  background: linear-gradient(90deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 0%) 100%);
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0px 20px var(--primary);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  }


.label:hover, .label:focus {
  color: #313133;
  /*transform: translateY(-6px);*/
}

label:hover::before, label:focus::before {
  opacity: 1;
}

label::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid var(--primary);
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  /*animation: ring 1.5s infinite;*/
  animation: ring 1.5s; 
  animation-iteration-count: 5;
  top: 40px;
  left: 40px;
}

label:hover::after, label:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

/*####################*/
.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    transition: all .2s;
    position: absolute;
    background: var(--primary) !important;
}

.btn:hover {
    /*transform: translateY(-3px);*/
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    /*transform: translateY(-1px);*/
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: white;
    color: #777;
}

.btn::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    z-index: -1;
    transition: all .4s;
    
}

.btn-white::after {
    background-color: var(--primary) !important;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.messages{
    height: 290px;
    overflow-y: scroll;
    border: solid 1px;
    padding: 0px 0px 45px 0px;
}
p:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	right: -10px;
	top: 10px;
	color: var(--primary) !important;
    background-color: white !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: white;
    border-bottom-color: white;
}
.resposta{
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    display: inline-block;
    padding: 10px 10px 10px 15px !important;
    border-radius: 6px;
    /*width: calc(100% - 15px);*/
    width:auto;
    /*min-width: 100px;*/
    position: relative;
    color: white !important;
    background-color: var(--primary) !important;
    border: 1px solid;
    /*display:flex !important;*/
}
.resposta:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	left: -10px;
	top: 10px;
	color: white !important;
    background-color: var(--primary) !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    border-bottom-color: var(--primary);
}
.logo-avatar{
    background: white;
    border-radius: 50%;
    padding: 5px;
}
.msg{
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    display: inline-block;
    padding: 10px 15px 10px 5px;
    border-radius: 6px;
    /*width: calc(100% - 15px);*/
    width:auto;
    /*min-width: 100px;*/
    position: relative;
    color: var(--primary);
    background-color: white;
    border: 1px solid;
}
.link:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	right: -10px;
	top: 10px;
	color: var(--primary) !important;
    background-color: var(--primary) !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    border-bottom-color: var(--primary);
    cursor: pointer;
}
.link{
    background: var(--primary);
    color: #fff !important;
}

.link:hover {
    background: #fff !important;
    color:  var(--primary)!important;
    cursor: pointer;
}
.link:hover:before {
    background-color: #fff !important;
    color:  var(--primary)!important;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: white;
    border-bottom-color: white;
    
}
.tex{
    position: relative !important;
}
body {
    background-color: #eee
}

.chat-btn {
    width: 80px !important;
    height: 80px !important;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 50%);
    position: absolute;
    right: 14px;
    bottom: 30px;
    cursor: pointer;
    z-index: 100000;
    position: fixed;
}

.chat-btn .close {
    display: none
}

.chat-btn i {
    transition: all 0.9s ease
}


#check:checked~.chat-btn i {
    display: block;
    pointer-events: auto;
    transform: rotate(180deg)
}
#check:checked~ label {
    top: 0px !important;
}
#check:checked~.chat-btn .comment {
    display: none
}

.chat-btn i {
    font-size: 22px;
    color: #fff !important
}

.chat-btn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: var(--primary);
    color: #fff;
    font-size: 22px;
    border: none
}

.wrapper {
    position: fixed;
    right: 20px;
    bottom: 10px;
    width: 400px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 50%);
    border-radius: 5px;
    opacity: 0;
    display:none;
    transition: all 0.4s;
    z-index: 100;
    border: 1px solid var(--primary);
    height: 90%;
}
@media only screen and (max-width: 479px) {
    .wrapper {
        width: 95% !important;
        right:2%;
        left:2%;
    }
    .lod{
        bottom: 190px !important;
    }
    #check:checked~ label {
        top: 40px !important;
        width: 50px !important;
        height: 50px !important;
    }
    #check:checked~ label::after {
        top: 25px;
        left: 25px;
    }
    .chat-btn {
        width: 50px !important;
        height: 50px !important;
    }
    .chat-btn::after {
        left: 25px;
        top: 25px;
    }
}
#check:checked~.wrapper {
    display: block;
    opacity:1;
    transition: opacity 1s;
}

.header {
    padding: 13px;
    background-color: var(--primary);
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    color: #fff
}

.chat-form {
    background-color:white;
    padding: 15px;
    bottom: 0px !important;
    position: absolute;
    width: 100%;
}

.chat-form input,
textarea,
button {
    margin-bottom: 10px
}

.chat-form textarea {
    resize: none
}

.form-control:focus,
.btn:focus {
    box-shadow: none
}

.btn,
.btn:focus,
.btn:hover {
    background-color: var(--primary) !important;
    border: var(--primary) !important;
}

#check {
    display: none !important;
}
</style>