<template>
  <div id="app">
    <transition mode="out-in">
      <router-view />
    </transition>
        <div class="block-ui" v-show="$store.state.pagamentoLoading" style="z-index: 9999">
      <div>
        <i class="material-icons text-white text-center d-flex justify-content-center cloud">sentiment_satisfied_alt</i>
        <h1 class="text-white text-center">Aguarde, processando seu pagamento.</h1>
        <loading color="#fff" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {
    storeCart() {
      return this.$store.getters.getCart;
    },
  },
  created() {
    try {
      if(window.localStorage.carrinho){
        this.$store.commit("UPDATE_CART_SESSION", JSON.parse(window.localStorage.carrinho));
      }
      if (sessionStorage.token) { 
        this.$store.commit("UPDATE_USER", JSON.parse(sessionStorage.userData));

          this.$http
            .validate()
            .then(response => {
          sessionStorage.setItem('userData', JSON.stringify(response.data))
              this.$store.commit(
                "UPDATE_USER",
                JSON.parse(sessionStorage.userData)
              );
            })
            .catch(() => {
              this.$store.dispatch("LOGOUT");
              this.$router.push({ name: "login" });
            });
  
        }
      } catch (e) {
        // tratamento do erro
        console.error('Ocorreu um erro ao acessar o sessionStorage: ', e.message);
      }
  },
};
</script>
