<template>
  <section class="header">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center pt-2 pb-2">
        <div class="col-6 col-sm-2 col-md-2 col-lg-2">
          <router-link :to="{ name: 'Dashboard' }">
            <img src="@/assets/logo_site.png" class="img-fluid" width="150" alt="logo" />
          </router-link>
        </div>
        <div class="col-6 col-sm-10 col-md-10 col-lg-10 d-md-flex d-flex justify-content-end">
          <div class="dropdown d-md-flex justify-content-center align-items-center white pl-3" id="user-menu"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img :src="'https://ui-avatars.com/api/?background=009de0&color=fff&name=' + $store.state.user.name"
              class="d-md-block d-none" alt="user" />
            <div class="user-data d-md-block d-none">
              <p class="user-name">
                <b>{{ $store.state.user.name }}</b>
              </p>

              <small class="text-lowercase">{{ $store.state.user.user }}</small>
            </div>
            <div class="d-flex justify-content-end d-md-none">
              <span class="material-icons text-dark pr-3 header-icon">person</span>
            </div>
            <div class="dropdown-menu dropdown-menu-right fade-2s" aria-labelledby="user-menu">
              <router-link :to="{ name: 'meuperfil' }" class="dropdown-item" type="button">
                <span class="material-icons">person</span> Meu Perfil
              </router-link>

              <router-link :to="{ name: 'Dashboard' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">home</span>Dashboard</router-link>

              <!--
              <router-link :to="{ name: 'relatoriosatendente' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">dvr</span>Relatórios</router-link>
              -->
              <router-link :to="{ name: 'relatorioplataformas' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">groups_2</span>Plataformas</router-link>

              <router-link :to="{ name: 'chatbot' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">smart_toy</span>Modelos Chat Bots</router-link>


              <router-link :to="{ name: 'cadastroatendente' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">people</span>Administradores</router-link>
              <!--
              <router-link :to="{ name: 'cms' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">rebase_edit</span>CMS Site</router-link>
              -->
            <!--
              <router-link :to="{ name: 'cadastroatendente' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'gestor'"><span class="material-icons">groups</span>Atendentes</router-link>
            -->



              <router-link :to="{ name: 'Dashboard' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'whitelabel'"><span
                  class="material-icons">home</span>Dashboard</router-link>


              <router-link :to="{ name: 'relatorioswhitelabel' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'whitelabel'"><span
                  class="material-icons">web</span>Relatórios</router-link>
                  
                  <router-link :to="{ name: 'atendentes' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'whitelabel'"><span class="material-icons">groups</span>Atendentes</router-link>

                <router-link :to="{ name: 'modelchat' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'whitelabel'"><span class="material-icons">smart_toy</span>Chats</router-link>




              <router-link :to="{ name: 'Dashboard' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'atendente'"><span
                  class="material-icons">home</span>Dashboard</router-link>

              <router-link :to="{ name: 'atendenterelatorios' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'atendente'"><span
                  class="material-icons">web</span>Relatórios</router-link>


              <!-- ### USUÁRIO ### -->

              <router-link :to="{ name: 'Dashboard' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'usuario'"><span class="material-icons">home</span>Dashboard</router-link>
              <router-link :to="{ name: 'chamados' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'usuario'"><span class="material-icons">question_answer</span>Meus
                Chamados</router-link>
              <!--
              <router-link :to="{ name: 'noticias-licitacao' }" class="dropdown-item" type="button"
                v-if="$store.state.user.group == 'usuario'"><span class="material-icons">web</span>Notícias
                Licitações</router-link>
              -->

              <div class="dropdown-divider"></div>
              <button class="dropdown-item" type="button" @click="logout">
                <span class="material-icons danger">exit_to_app</span> Sair
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container  sub-menu">
      <div class="row">
        <div class="col px-5">
          <ul class="navbar-nav flex-row sub-menu-nav">
            <!-- ### GESTOR ### -->
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'Dashboard' }" class="nav-link">Dashboard</router-link>
            </li>
            <!--
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'relatoriosatendente' }" class="nav-link">Relatórios</router-link>
            </li>
            -->
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'relatorioplataformas' }" class="nav-link">Plataformas</router-link>
            </li>

            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'chatbot' }" class="nav-link">Modelos Chat Bots</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'cadastroatendente' }" class="nav-link">Administradores</router-link>
            </li>
            <!--
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'cms' }" class="nav-link">CMS Site</router-link>
            </li>
            -->




            <li class="nav-item" v-if="$store.state.user.group == 'whitelabel'">
              <router-link :to="{ name: 'Dashboard' }" class="nav-link">Dashboard</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'whitelabel'">
              <router-link :to="{ name: 'relatorioswhitelabel' }" class="nav-link">Relatórios</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'whitelabel'">
              <router-link :to="{ name: 'atendentes' }" class="nav-link">Atendentes</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'whitelabel'">
              <router-link :to="{ name: 'modelchat' }" class="nav-link">Chats</router-link>
            </li>
            
          <!--
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'relatoriosatendente' }" class="nav-link">Relatórios</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'usuarios' }" class="nav-link">Usuários</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'cadastro' }" class="nav-link">Atendentes</router-link>
            </li>
          -->
            <!--
            <li class="nav-item"  v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{ name: 'chamados' }" class="nav-link">Chamados</router-link>
            </li>
            -->
            <!-- ### ATENDENTE ### -->
            <li class="nav-item" v-if="$store.state.user.group == 'atendente'">
              <router-link :to="{ name: 'Dashboard' }" class="nav-link">Dashboard</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'atendente'">
              <router-link :to="{ name: 'atendenterelatorios' }" class="nav-link">Relatórios</router-link>
            </li>
            <!--
            <li class="nav-item"  v-if="$store.state.user.group == 'atendente'">
              <router-link :to="{ name: 'chamados' }" class="nav-link">Chamados</router-link>
            </li>
            -->
            <!-- ### USUÁRIO ### -->
            <li class="nav-item" v-if="$store.state.user.group == 'usuario'">
              <router-link :to="{ name: 'Dashboard' }" class="nav-link">Dashboard</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'usuario'">
              <router-link :to="{ name: 'chamados' }" class="nav-link">Meus Chamados</router-link>
            </li>

            <!--
            <li class="nav-item" v-if="$store.state.user.group == 'usuario'">
              <router-link :to="{ name: 'noticias-licitacao' }" class="nav-link">Notícias Licitações</router-link>
            </li>
          -->

          </ul>
        </div>
      </div> 
    </div>
    <div class="submenu"></div>
    <Chat v-if="$store.state.user.group == 'usuario'" />
  </section>
</template>

<script>

import Sidebar from "primevue/sidebar";
import Chat from "@/components/Chat/index.vue";
export default {
  name: "TheHeader",
  components: {
    Chat,
    Sidebar,
  },
  data() {
    return {
      visibleLeft: false,
      items: [
        {
          label: "Categorias",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "New",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark",
                  to: "/meu-perfil",
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video",
                },
              ],
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-trash",
            },
            {
              separator: true,
            },
            {
              label: "Export",
              icon: "pi pi-fw pi-external-link",
            },
          ],
        },
      ],
      notificacoes: [
        {
          Titulo: "Teste",
          mensagem: "Descrição do teste de notificação",
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.$store.commit("SET_MODAL", true);
    },
    async logout() {
      this.$store.state.user = [];
      await this.$store.dispatch("LOGOUT");
      await this.$router.push({ name: "login" });
    },
  },
  mounted() {
    document.title = this.$titleSite;
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .sub-menu {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .header-icon {
    padding: 0 !important;
    margin: 0 !important;
    color: var(--dark-2) !important;
  }
}

.menu-responsive {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background: #03abeb;
  padding: 0.5rem;
  border-radius: 50%;
  color: white;
}

@media only screen and (max-width: 479px) {
  .modd {
    display: none;
  }

}
</style>