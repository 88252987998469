<template>
      <Produtos />
</template>
<script>
import Produtos from "@/components/ProdutosLista";
export default {
  name: "HomeAtendente",
  components: {
    Produtos
  },
  data() {
    return {
      loading: false,
      totalChamados: 0,
      totalPendentes: 0,
      all: 0,
      totalAndamento: 0,
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },

  methods: {
    async getRelatorios() {
      this.load = true;
      this.$http
        .get("/api/usuario/relatorios")
        .then((response) => {
          this.totalChamados = response.data["totalChamados"];
          this.totalPendentes = response.data["totalPendentes"];
          this.totalAndamento = response.data["totalAndamento"];
          this.all = response.data["all"];
          this.load = false;
          this.loading = false;
          Chart.reinit();
        })
        .catch(() => { });
    },
  },
  mounted() {
    //this.getRelatorios();
  },
}; 
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}
</style>